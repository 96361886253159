.main,
.topbannerAd,
.footerAd {
  width: 100%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.main {
  padding-top: 20px;
  padding-bottom: 20px;
}

.footerAd {
  margin-bottom: 20px;
}
