.root {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 50px;
  box-shadow: inset 0 -1px 0 0 var(--color-alt-blue-2);
}

.list {
  display: flex;
  align-items: flex-start;
  overflow: scroll hidden;
  height: 70px;
}

.link {
  display: flex;
  padding: 15px 10px;
  color: var(--color-carbone-base);
  font-family: var(--manrope);
  font-size: var(--font-size-default);
  line-height: var(--line-height-default);
  font-weight: var(--font-weight-bold);
  white-space: nowrap;
  transition: color var(--transition-duration);

  &:active,
  &:focus {
    color: var(--color-blue-middle);
  }

  &.active {
    color: var(--color-blue-base);
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}
