.animated {
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      rgba(239 245 255/ 0) 0%,
      #e0ebff 50.52%,
      rgba(239 245 255/ 0) 100%
    );
    animation: loading 1.5s infinite cubic-bezier(0.4, 0, 0.3, 1);

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }
}

.item {
  border-radius: 5px;
  height: 16px;
  background-color: var(--color-alt-blue-1);
}

.title {
  padding: 20px 0 20px 10px;
  border-bottom: 1px solid var(--color-alt-blue-2);

  & div {
    width: 291px;
    height: 24px;
  }
}

.categories {
  display: flex;
  padding: 20px 0 20px 10px;
  border-bottom: 1px solid var(--color-alt-blue-2);

  & div {
    width: calc(25% - 20px);

    & + div {
      margin-left: 20px;
    }
  }
}

.row {
  display: flex;
  padding: 20px 10px;
}

.select {
  height: 40px;
  flex: 1;
}

.button {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-left: 20px;
}

.list {
  padding: 0 10px;

  & div {
    & + div {
      margin-top: 20px;
    }

    &:nth-of-type(3n) {
      width: 225px;
    }

    &:nth-of-type(3n + 1) {
      width: 100%;
    }

    &:nth-of-type(3n + 2) {
      width: 300px;
    }
  }
}

.channelInfo {
  padding: 20px 10px;
  border-bottom: 1px solid var(--color-alt-blue-2);
}

.channelTitle {
  & div {
    height: 24px;
  }

  & div:first-child {
    width: 291px;
  }

  & div:last-child {
    width: 180px;
    margin-top: 6px;
  }
}

.channelActions {
  display: flex;
  margin-top: 15px;

  & div:nth-of-type(1),
  & div:nth-of-type(2) {
    width: 30px;
    height: 30px;
  }

  & div:nth-of-type(2) {
    margin-left: 20px;
  }

  & div:nth-of-type(3) {
    width: 53px;
    height: 30px;
    border-radius: 15px;
    margin-left: auto;
  }
}

.channelText {
  margin-top: 10px;

  & div {
    & + div {
      margin-top: 5px;
    }

    &:nth-of-type(3n) {
      width: 250px;
    }
  }
}

.selection {
  padding: 0 10px 15px;

  & div {
    margin-top: 20px;

    &:nth-of-type(3n + 2) {
      width: 300px;
    }

    &:nth-of-type(3n + 3) {
      width: 225px;
    }
  }
}

.show {
  padding: 20px 10px;
  box-shadow: 0 10px 10px -10px rgba(49 94 251 / 0.4);
}

.showTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  & div {
    height: 24px;

    &:first-child {
      width: 220px;
    }

    &:last-child {
      width: 30px;
      height: 30px;
    }
  }
}

.image {
  height: 195px;
  margin-bottom: 15px;

  &.favorite {
    margin: 15px 0;
  }
}

.showActions {
  display: flex;

  & div {
    height: 30px;
    border-radius: 15px;
    width: 54px;

    &:nth-of-type(3) {
      margin-left: auto;
    }

    &:nth-of-type(2) {
      margin-left: 10px;
    }
  }
}

.showButton {
  height: 40px;
  border-radius: 25px;
  margin-top: 15px;
}

.showChannel {
  margin: 30px 0 20px;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;

  & div:first-child {
    width: 180px;
    height: 20px;
  }

  & div:last-child {
    width: 20px;
    height: 20px;
  }
}

.showSelection {
  padding: 0 10px;

  & div:first-child {
    width: 85px;
  }

  & div:last-child {
    max-width: 325px;
    width: 100%;
    margin-top: 10px;
  }

  & + .showSelection {
    margin-top: 20px;
  }
}

.content {
  padding: 0 10px;

  &.withBorder {
    border-bottom: 1px solid var(--color-alt-blue-2);
    padding: 0 10px 20px;
  }
}

.pageTitle {
  margin: 20px 0;
  height: 24px;
  width: 255px;
}

.premiereTitle {
  display: flex;
  justify-content: space-between;

  & div:first-child {
    width: 180px;
    height: 20px;
  }

  & div:last-child {
    width: 20px;
    height: 20px;
  }
}

.premiereCategories {
  display: flex;
  margin-top: 15px;

  & div {
    border-radius: 25px;
    height: 30px;
  }

  & div:first-child {
    width: 100px;
  }

  & div:last-child {
    width: 81px;
    margin-left: 10px;
  }
}

.premiereText {
  margin-top: 15px;

  & div {
    & + div {
      margin-top: 5px;
    }

    &:nth-of-type(3n) {
      width: 250px;
    }
  }
}

.premiereTime {
  display: flex;
  margin: 20px 0 15px;

  & div:nth-of-type(1),
  & div:nth-of-type(2) {
    width: 100px;
  }

  & div:nth-of-type(1) {
    margin-right: 20px;
  }

  & div:nth-of-type(3) {
    margin-left: auto;
    width: 20px;
  }
}

.favoriteTitle {
  margin: 20px 0;
  width: 152px;
  height: 24px;
}

.favoriteChannel {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.subtitle {
  width: 140px;
  margin-bottom: 15px;
}

.favoriteSchedule {
  & div {
    &:first-child {
      width: 85px;
    }

    &:last-child {
      max-width: 325px;
      width: 100%;
    }

    & + div {
      margin-top: 10px;
    }
  }

  & + .favoriteSchedule {
    margin-top: 20px;
  }
}

.footer {
  display: flex;
  padding: 20px 10px 0;
  height: 35px;
  overflow: hidden;

  & div {
    height: 20px;
    margin-bottom: -10px;

    &:first-child {
      width: 80px;
      border-radius: 25px;
      margin-right: 20px;
    }

    &:last-child {
      width: 180px;
    }
  }
}

.newsTitle {
  width: 200px;
  height: 25px;
}

.newsList {
  margin: 20px 0;
}

.newsItem {
  display: flex;
  margin-bottom: 20px;
  border-radius: 5px;

  &:last-child {
    margin-bottom: 0;
  }
}

.newsImage {
  margin-right: 20px;
  width: 90px;
  height: 90px;
}

.newsText {
  width: 100%;

  & > div {
    margin-bottom: 5px;

    &:nth-of-type(2) {
      margin-bottom: 10px;
    }

    &:nth-of-type(3) {
      width: 110px;
    }
  }
}

@keyframes loading {
  to {
    transform: translateX(100%);
  }
}
